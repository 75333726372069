<template>
  <picture :style="pictureStyle">
    <slot></slot>
    <img v-if="imageSrc" :width="width" :src="imageSrc" alt="" :loading="loading"/>
  </picture>
</template>
<script>
import {Storage} from "aws-amplify";

export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    loading: {
      type: String,
      default: 'eager'
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    width: {
      type: String,
      default: "auto"
    }
  },
  data(){
    return {
      imageSrc: ''
    }
  },
  async created(){
    this.setImage()
  },
  computed: {
    pictureStyle() {
      return {
        'aspect-ratio': this.aspectRatio,
        'width': this.width + 'px'
      }
    }
  },
  methods: {
    async setImage(){
        this.imageSrc = await Storage.get(this.src, { download: false })
    }
  }
}
</script>
<style lang="scss" scoped>
picture {
  object-fit: cover;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>