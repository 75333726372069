<template>
  <div class="bh-block auth-logo">
    <n-image
        :width="width" style="max-wide: 100%"
        class="amplify-image"
        alt="Amplify logo"
        preview-disabled
        :src="require('../assets/images/blindher-logo.png')"
    />
  </div>
</template>
<script>
import {NImage} from 'naive-ui'
export default {
  name: "AuthLogo",
  components: {NImage},
  props: {
    width: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.auth-logo {
  margin-top: 2rem;
}
</style>