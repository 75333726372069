<template>
  <layout name="LayoutDefault">
    <n-config-provider :theme="darkTheme">
      <div v-if="series" class="bh-flex">
        <div class="radius-round title-box">
          {{$t('Séries')}}
        </div>
      </div>
      <div class="container">
        <h1>{{$t('Séries')}}</h1>
        <div v-if="series">{{series.length}} séries</div>
        <div class="serie-info ml-1 mt-4" v-if="series">
          <div v-for="serie in series" :key="serie.id" >
            <router-link class="is-inline-block" :to="`/series/${serie.id}`" >
              <serie-card class="mb-4" v-bind="serie"/>
            </router-link>
          </div>
          </div>

      </div>
    </n-config-provider>
  </layout>
</template>

<script>
import { defineComponent } from 'vue'
import {API, graphqlOperation} from 'aws-amplify';
import Layout from './../../layouts/layout';

import { NConfigProvider, darkTheme } from 'naive-ui'
import SerieCard from "@/components/serie/SerieCard";
import {QUERY_LiST_SERIES} from "@/query/serie";

export default defineComponent({
  setup() {
    return {
      darkTheme
    }
  },
  name: 'App',
  components: {
    SerieCard,
    NConfigProvider,
    Layout,
  },
  data() {
    return {
      series: [],
      storyThumbnail: '',
      loading: true,
      sortItems: [],
    }
  },
  async created() {
    await this.getSeries()
  },
  methods: {
    secondToMinute(time){
      return new Date(time * 1000).toISOString().substring(14, 19)
    },
    async getSeries() {

      try {
        await API.graphql(graphqlOperation(QUERY_LiST_SERIES, {
          filter: {_deleted: {ne: true}}
        })).then(response => {
          if (response.data.listSeries.items) {
            this.series = response.data.listSeries.items
          }
        }).finally(() => {
          this.$isLoading(false) // hide loading screen
        })

      } catch (e) {
        console.log(e)
        throw new Error('Something went wrong!')
      }
    }
  }
})
</script>
<style scoped lang="scss">
@import 'src/assets/scss/main.scss';
.story-back {
  justify-content: space-between;
  h1 {
    flex-grow: 1;
    text-align: center;
    padding-right: 45px;
  }
}
.story-info {
  width: 40rem;
  max-width: 100%;
  margin: 0 auto;

  a {
    max-width: 100%;
    picture {
      max-width: 100%;
    }
  }
}
.click-to-play {
  font-size: 3rem;
  color: #fff;
  left: 50%;
  top: 50%;
  margin-left: -2.5rem;
  margin-top: -2.5rem;
  width: 5rem;
}
.title-box {
  text-align: center;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: var(--love-color)
}
</style>