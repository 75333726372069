export const FRAGMENT_STORY_CARD = /* GraphQL */ `
    fragment storyCard on Story {
        description
        duration
        id
        image
        name
        isTrial
        tags{
            items{
                tag {
                    id
                }
            }
        }
    }
`

export const FRAGMENT_STORY_DETAIL = /* GraphQL */`
    fragment storyDetail on Story {
        createdAt
        description
        duration
        file
        id
        image
        level
        name
        isTrial
        updatedAt
        tags {
            items {
                tag{
                    id
                    name
                }
            }
        }
    }
`

export const QUERY_GET_STORY = /* GraphQL */`
    query GetStory($id: ID!) {
        getStory(id: $id) {
            ...storyDetail
        }
    }
    ${FRAGMENT_STORY_DETAIL}
`

export const QUERY_LIST_STORY = /* GraphQL */ `
    query ListStories(
        $nextToken: String
        $limit: Int
        $sortDirection: ModelSortDirection
    ) {
        listStories( limit: $limit, sortDirection: $sortDirection, nextToken: $nextToken) {
            items {
                ...storyCard
            }
            nextToken
        }
    }
    ${FRAGMENT_STORY_CARD}
`;

export const QUERY_LIST_STORY_BY_ID = /* GraphQL */ `
    query ListStories(
        $storyId: ID!
    ) {
        listStories(filter: {id: {eq: $storyId}}) {
            items {
                ...storyCard
            }
            nextToken
        }
    }
    ${FRAGMENT_STORY_CARD}
`;
export const QUERY_WATCH_MOST_LIST_STORY = /* GraphQL */ `
    query ListStories(
        $ids: [ID]
        $nextToken: String
    ) {
        listStories(filter: {id: {eq: $ids[0]}}) {
            items {
                ...storyCard
            }
        }
        queryTwo:listStories(filter: {id: {eq: $ids[1]}}) {
            items {
                ...storyCard
            }
        }
    }
    ${FRAGMENT_STORY_CARD}
`;

export const QUERY_SEARCH_STORY = /* GraphQL */ `
    query ListStories(
        $name: String
        $limit: Int
        $nextToken: String
    ) {
        listStories(filter: {name: {contains: $name}}, limit: $limit, nextToken: $nextToken) {
            items {
                ...storyCard
            }
            nextToken
        }
    }
    ${FRAGMENT_STORY_CARD}
`;