export default {
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "épisodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["épisodes"])},
  "EMAIL_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email vérifié"])},
  "Favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoris"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histoires"])},
  "HOME_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "menu": {
    "Favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoris"])},
    "Offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrir"])},
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histoires"])},
    "Accueil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])}
  },
  "Séries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séries"])},
  "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'abonnement: une fois l'essai gratuit terminé, l'abonnement annuel se voit automatiquement renouvelé tous les ans. L'abonnement mensuel se voit automatiquement renouvelé tous les mois. Si vous vous désabonnez avant la fin de la période d'essai gratuite, le nombre de jours restant sera perdu dès l'annulation de votre abonnement. Offre valable uniquement pour les nouveaux utilisateurs."])},
  "user": {
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])}
  },
  "title": {
    "vos_envies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos envies"])},
    "nouveautes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveautés"])},
    "voix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voix"])},
    "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séries"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Profil"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
    "histoires_gratuites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histoires gratuites"])}
  },
  "time": {
    "MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIN"])}
  },
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totale"])},
  "error": {
    "Email is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail est requis"])}
  },
  "register-with-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez-vous connecter / Inscrire"])},
  "stories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["histoires"])}
}