<template>
  <div class="bh-flex item radius-medium p-2 mobile-p-1">
    <div class="is-relative">
      <bh-s3-image width="150" :aspect-ratio="1" class="radius-medium" :src="image"/>
    </div>
    <div class="info pl-4">
      <h5 class="size-5 mb-1 mt-2 font-type-body is-hidden">{{name}}</h5>
      <div class="mt-2 size-6">{{storyAmount}} {{$t('history')}}, {{$t('duration')}} {{$t('total')}}: {{totalPlayTime}}</div>
    </div>
  </div>

</template>
<script>

import {getSeriesTotalPlayTime} from "@/lib/common";
import {secondToMinute} from "@/lib/time";

import BhS3Image from "@/components/BhS3Image";
export default {
  name: 'SerieCard',
  components: {BhS3Image},
  props: {
    description: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    stories: null
  },
  data(){
    return {
      //stories: [],
      storyAmount: 0,
      totalPlayTime: 0
    }
  },
  async mounted(){
    let storiesList = []
    for await (const story of this.stories) {
      storiesList.push(story)
    }

    this.storyAmount = storiesList.length

    this.totalPlayTime = secondToMinute(getSeriesTotalPlayTime(storiesList))
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
.item {
  background: $highlight_background;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .item {
    picture {
      width: 25%;
      max-width: 100px;
    }

  }
}
</style>