<template>
        <div class="subscription-form bh-flex">
          <div>
            <auth-logo v-if="showLogo" class="text-center" width="200"/>
            <h4 class="mb-2 size-5 text-center text-white">Blindher est un objet sonore divertissant. Le vôtre.</h4>
            <n-list class="mb-1">
              <n-list-item>
                <n-thing>
                  <Checkmark20Filled style="width: 1rem;"/> Essai gratuit sans engagement<br/>
                  <Checkmark20Filled style="width: 1rem;"/> Un accès illimité à nos histoires<br/>
                  <Checkmark20Filled style="width: 1rem;"/> Une nouvelle histoire par semaine
                </n-thing>
              </n-list-item>
            </n-list>
            <div v-if="plans.length">
              <div v-for="(plan, planIndex) in plans" :key="plan.productId">
                <n-card @click="selectedPlan = planIndex" class="plan-card radius-medium mb-3 cursor-pointer" :class="(selectedPlan == planIndex) ? 'active' : ''">
                  <strong>{{plan.fee}}{{plan.currencySign}} {{plan.feeLabel}}</strong>
                  <span v-if="plan.subFeeLabel"><br/>{{plan.subFeeLabel}}</span>
                  <p class="mb-0 mt-1">{{plan.label}}</p>
                  <div class="offer py-1 px-3 radius-medium" v-if="plan.offer">{{plan.offer}}</div>
                </n-card>
              </div>
            </div>

            <p class="term-text text-center text-white">
              {{$t('subscription_term')}}
            </p>
            <div class="bh-flex text-strong text-pink mt-6"><a class="mr-1" href="https://blindher.com/mentions-legales/"><strong>Restaurer les achats</strong></a> · <a class="ml-1" href="https://blindher.com/cga/"><strong>Conditions générales</strong></a></div>

          </div>
          <div class="text-center mt-6">
            <n-button @click="createSubscription" class="submit-button bg-pink py-2 px-9 radius-half-round text-white size-6">Commencer mon essai gratuit</n-button>
          </div>
        </div>

</template>
<script>
import {NCard, NList, NListItem, NThing, NButton} from 'naive-ui'
import AuthLogo from "@/components/AuthLogo.vue";
import Checkmark20Filled from '@vicons/fluent/Checkmark20Filled';
import {Auth, DataStore} from "aws-amplify";
import {StripeContact} from "@/models";
import $router from "vue-router-back-button/src/history";
import store from "@/store/store";

const stripe = require("stripe")(process.env.VUE_APP_STRIPE_CLIENT_SECRET)

export default {
  name: "SubscriptionForm",
  components: {AuthLogo, NCard, Checkmark20Filled, NList, NListItem, NThing, NButton},
  props: {
    showLogo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedPlan: 0,
      plans: []
    }
  },
  async created(){
    this.getPlans();
    await this.checkIfSubscribed()
  },
  methods: {
    async checkIfSubscribed() {
      await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(async currentUser => {
        if (currentUser) {
          await DataStore.query(StripeContact).then(async stripeContacts => {
            if (stripeContacts && stripeContacts.length > 0) {
              this.$store.commit('setStripeContactID', stripeContacts[0].customerID)
              await this.checkStripeData(stripeContacts[0].customerID)
            }
          })
        }
      })
    },
    async checkStripeData(contactId) {
      const customer = await stripe.customers.retrieve(contactId, {expand: ['subscriptions']});
      if (customer) {
        const lastSubscription = customer.subscriptions.data[0]
        if (lastSubscription) {
          $router.back()
        }
      }
    },
    getPlans(){
      this.plans = [
        {
          id: 1,
          productId: process.env.VUE_APP_MONTHLY_PLAN,
          annualFee: null,
          fee: 8.99,
          feeLabel: 'Mensuel',
          subFeeLabel: '',
          label: 'Gratuit les 7 premiers jours',
          offer: '',
          trialDays: 7,
          currencySign: '€',
          type: 'monthly'
        },
        {
          id: 2,
          fee: 44.99,
          productId: process.env.VUE_APP_ANNUAL_PLAN,
          feeLabel: 'Annuel',
          subFeeLabel: '(3.75/mois)',
          label: 'Gratuit les 14 premiers jours',
          offer: 'Meilleure offre',
          trialDays: 14,
          currencySign: '€',
          type: 'annually'
        }
      ]

      console.log(this.plans)

      if(this.selectedPlan == null){
        this.selectedPlan = this.plans[0].productId
      }
    },
    async createSubscription() {
      console.log(this.plans[this.selectedPlan])

      const {attributes} = await Auth.currentAuthenticatedUser()

      if(attributes){
        try {
          console.log(attributes)
          console.log(attributes.sub)

          let userSubscriptionId = attributes.sub

          const notExistingPlan = this.notExistSubscription(
              userSubscriptionId
          )

          console.log(notExistingPlan)

          const selectedPlanObject = this.plans[this.selectedPlan]

          if(selectedPlanObject){
            await this.prepareStripeContact()
          }

        } catch (err) {
          this.alert2 = true
          this.disabled = false
          this.alertTxt = 'An error has occurred. Try again later'
        }
      }

    },
    async prepareStripeContact(){
      await DataStore.query(StripeContact).then(async stripeContact => {
        if (stripeContact && stripeContact.length > 0) {
          console.log(stripeContact[0]);
          store.commit('setStripeTempCid', stripeContact[0].customerID)
          await this.subscriptionSession(stripeContact[0].customerID)
        } else {
          const newStripeContact = await stripe.customers.create();
          if (newStripeContact) {
            console.log(newStripeContact);
            await DataStore.save(new StripeContact({
              customerID: newStripeContact.id,
              stripeMode: process.env.VUE_APP_STRIPE_MODE
            }))
            store.commit('setStripeTempCid', newStripeContact.id)
            await this.subscriptionSession(newStripeContact.id)
          }
        }
      })
    },
    async subscriptionSession(customer_id){
      console.log(customer_id)
      const selectedPlanObject = this.plans[this.selectedPlan]

      const session = await stripe.checkout.sessions.create({
        customer: customer_id,
        allow_promotion_codes: true,
        payment_method_types: ["card"],
        mode: "subscription",
        currency: 'EUR',
        line_items: [
          {
            quantity: 1,
            price: selectedPlanObject.productId
          }
        ],
        subscription_data: {
          trial_settings: {
            end_behavior: {
              missing_payment_method: 'cancel',
            },
          },
          trial_period_days: selectedPlanObject.trialDays,
        },
        success_url: `${process.env.VUE_APP_SITE_URL}/abonnement/abonnement-reussi?session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${process.env.VUE_APP_SITE_URL}/abonnement/diminue`,
      })
      console.log(session)
      if(session){
        window.location = session.url
      }
    },
    async notExistSubscription() {

      /*const res = await axios.post(`${url}/subs`, {
          customerId, priceId
      });
      return res;*/

      return true
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/variables';
.subscription-form {
  justify-content: space-around;
  min-height: calc(100vh - 76px);
  flex-direction: column;

  max-width: 600px;
  margin: 0 auto;
}
.plan-card {
  position: relative;
  border: 2px solid #ccc;
  background: transparent;
  color: #fff;

  .offer {
    position: absolute;
    right: 15px;
    top: 15px;
    background: yellow;
    color: #000;
  }

  &.active {
    background: $active_color;

    color: #fff;
  }
}

.submit-button.n-button {
  border: 1px solid $active_color;
  &:hover,
  &:focus {
    background: #fff;
    color: $active_color;
  }
}

.term-text {
  font-size: 0.9rem;
  line-height: 1.1rem;
}
</style>
<style lang="scss">
.n-list {
  background: transparent;
  color: #fff;
  --n-text-color: #fff;

  .n-thing {
    color: #fff;
  }
}
</style>