import {FRAGMENT_STORY_CARD} from "@/query/story";

export const FRAGMENT_TAGE = /* GraphQL */`
    fragment tagCard on Tag {
        createdAt
        id
        name
    }
`

export const QUERY_LIST_TAG = /* GraphQL */ `
    query ListTags(
        $limit: Int
        $nextToken: String
    ) {
        listTags(limit: $limit, nextToken: $nextToken) {
            items {
                ...tagCard
            }
            nextToken
        }
    }
    ${FRAGMENT_TAGE}
`;

export const QUERY_LIST_TAG_STORIES = /* GraphQL */ `
    query ListTags(
        $limit: Int
        $nextToken: String,
        $storyLimit: Int
    ) {
        listTags(limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                stories(sortDirection: DESC, limit: $storyLimit) {
                    items {
                        story {
                            ...storyCard
                        }
                    }
                }
            }
            nextToken
        }
    }
    ${FRAGMENT_STORY_CARD}
`;

export const QUERY_GET_TAG = /* GraphQL */ `
    query GetTag($id: ID!) {
        getTag(id: $id) {
            createdAt
            id
            name
        }
    }
`;

export const QUERY_GET_TAG_STORIES = /* GraphQL */ `
    query GetTag($id: ID!) {
        getTag(id: $id) {
            createdAt
            id
            name
            stories {
                items {
                    story {
                        ...storyCard
                    }
                }
            }
        }
    }
    ${FRAGMENT_STORY_CARD}
`;

export const QUERY_SEARCH_TAG = /* GraphQL */ `
    query ListTags($keyword: String){
        listTags(filter: {name: {contains: $keyword}}){
            items {
                ...tagCard
            }
        }
    }
    ${FRAGMENT_TAGE}
`