<template>
  <div class="LayoutDefault ">
  <authenticator class="auth-wrapper" :form-fields="formFields" initial-state="resetPassword">
    <template v-slot:header>
      <AuthLogo class="auth-box-space"/>
    </template>
    <template v-slot:reset-password-header>
      <h4
          class="amplify-heading text-white"
      >
        Reinitialisez votre mot<br/> de passe
      </h4>
    </template>

    <template v-slot:reset-password-footer>
      <div class="text-center">
        <router-link to="/">Retour à la connexion</router-link>
      </div>
    </template>

  </authenticator>
  </div>
</template>
<script>
import {Authenticator, translations} from '@aws-amplify/ui-vue'
import AuthLogo from "@/components/AuthLogo";

import { I18n } from 'aws-amplify';

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

I18n.putVocabularies({
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",
    'Send Code': "Envoyer",
    'Reset your Password': 'Modifier votre mot de passe'
  },
});
export default {
  components: {Authenticator, AuthLogo}
}
</script>
<script setup>

const formFields = {

  resetPassword: {
    username: {
      label: 'Email *',
      labelHidden: false,
      placeholder: 'Entrez votre email',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Indiquer votre code de confirmation:',
      label: 'Code de confirmation',
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Saisir votre mot de passe:',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'Code de confirmation',
      placeholder: 'Indiquer votre code de confirmation:',
      isRequired: false,
    },
  },
};
</script>
<style lang="scss">
[data-amplify-authenticator-resetpassword] {
  footer {
    button {
      margin: 2rem 0;
    }
    button + button {
      display: none;
    }
  }
}
</style>