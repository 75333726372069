<template>
  <layout name="LayoutDefault" :class="{'mobile-app-suggest': showAppSuggest}">
    <div class="container">
        <div v-if="introImage">
          <bh-s3-image class="intro-image radius-medium" width="550" :aspect-ratio="5/2" :src="introImage">
            <line-audio-player class=" line-player-box" v-if="introAudio" :audioMedia="introAudio"/>
          </bh-s3-image>
        </div>
      <div v-if="trialStories">
        <div  class="mt-10">
          <h2 class="mb-2 list-title">{{$t('title.histoires_gratuites')}}</h2>
          <swiper
              slides-per-view="auto"
              :space-between="40"
              :loop="false"
          >
            <swiper-slide class="swiper-item" v-for="story in trialStories" :key="story.id" >
              <router-link class="item-link" :to="`/stories/${story.id}`" >
                <story-card class="mb-4 " :is-subscribed="isSubscribed" :key="story.id" v-bind="story"/>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>
        <div v-if="newStories">
          <div  class="mt-10">
            <h2 class="mb-2 list-title">{{$t('title.nouveautes')}}</h2>
            <swiper
                slides-per-view="auto"
                :space-between="40"
                :loop="false"
            >
              <swiper-slide class="swiper-item" v-for="story in newStories" :key="story.id" >
                <div class="cursor-pointer" v-if="!story.isTrial && !isSubscribed">
                  <story-card @click="makeSubscription(story)" class="mb-4 " :is-subscribed="isSubscribed" :key="story.id" v-bind="story"/>
                </div>
                <router-link v-else class="item-link" :to="`/stories/${story.id}`" >
                  <story-card class="mb-4 " :is-subscribed="isSubscribed" :key="story.id" v-bind="story"/>
                </router-link>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div v-else class="skeleton-wrap">
          <div v-for="skelTop in 2" :key="skelTop" class="mt-5">
            <n-skeleton class="m-2 mb-3" height="30px" width="66%" :sharp="false" />
            <div class="bh-flex" style="flex-wrap: nowrap; flex-direction: row; justify-content: flex-start">
              <n-skeleton style="flex: 200px 0 0;" class="bh-flex m-2" v-for="loadItem in 4" :key="loadItem" height="200px" width="200px" :sharp="false" />
            </div>
          </div>
        </div>
        <div v-if="sortItems.length > 0">
          <div v-for="itemGroup in sortItems" class="mt-10" :key="itemGroup[0]">
            <h2 class="mb-2 list-title">{{itemGroup.name}}</h2>
            <swiper
                :loop="false"
                slides-per-view="auto"
                :space-between="30"
                v-if="itemGroup.items.length"
            >
              <swiper-slide class="swiper-item" v-for="item in itemGroup.items" :key="item.story.id" >
                <div class="cursor-pointer" v-if="!item.story.isTrial && !isSubscribed">
                  <story-card @click="makeSubscription(item.story)" class="mb-4 " :is-subscribed="isSubscribed" :key="item.story.id" v-bind="item.story"/>
                </div>
                <router-link v-else class="item-link" :to="`/stories/${item.story.id}`" >
                  <story-card class="mb-4" :is-subscribed="isSubscribed" :key="item.story.id" v-bind="item.story"/>
                </router-link>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div v-else class="skeleton-wrap">
          <div v-for="skel in 8" :key="skel" class="mt-5">
            <n-skeleton class="m-2 mb-3" height="30px" width="66%" :sharp="false" />
            <div class="bh-flex" style="flex-wrap: nowrap; flex-direction: row; justify-content: flex-start">
              <n-skeleton style="flex: 200px 0 0;" class="bh-flex m-2" v-for="loadItem in 4" :key="loadItem" height="200px" width="200px" :sharp="false" />
            </div>
          </div>
        </div>
        <div>
          <div>
            <h2 class="list-title">{{$t('Séries')}}</h2>
            <div class="serie-list" v-for="serie in series" :key="serie.id">

              <router-link class="item-link bh-flex mb-5" :to="`/series/${serie.id}`" >
                <serie-card v-bind="serie" :stories="serie.stories"/>
              </router-link>
            </div>
          </div>
        </div>
        <n-modal class="subscription-box" v-model:show="showSubscription" transform-origin="center" preset="card">
          <template #header-extra>
          </template>
          <subscription-form />
        </n-modal>
    </div>
    <mobile-app-stores-suggest v-if="showAppSuggest" @close="stopStoreSuggest"/>
  </layout>
</template>

<script>
import { defineComponent } from 'vue'
import {API, graphqlOperation, Storage, DataStore, Auth} from 'aws-amplify';
import {Predicates, SortDirection} from '@aws-amplify/datastore'

import Layout from '../layouts/layout';

import { NSkeleton, NModal } from 'naive-ui'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import StoryCard from "@/components/story/StoryCard";
import BhS3Image from "@/components/BhS3Image";

import LineAudioPlayer from "@/components/LineAudioPlayer";
/*import {QUERY_GET_SERIE} from "@/query/serie";
import {getSeriesTotalPlayTime} from "@/lib/common";
import {secondToMinute} from "@/lib/time";*/

import SerieCard from "@/components/serie/SerieCard";
import {QUERY_LIST_TAG_STORIES} from "@/query/tag";
import SubscriptionForm from "@/components/SubscriptionForm.vue";
import {mapState} from "vuex";
import {Serie, Story, Tag} from "@/models";
import MobileAppStoresSuggest from "@/components/MobileAppStoresSuggest.vue";

const allList = [
    ['Les plus écoutées','df592cb5-2d1f-43bf-93c8-f5f4c944ab03'],
    ['Laissez-vous tenter', 'f49679f2-bc7e-4d18-82b1-29146c0afee0'],
    ['Elle et elle', 'a1c5d6c6-f505-49f3-a8b8-9da3b2383749'],
    ['Elle et lui', '0bdfa5fa-ff77-4e0c-999a-6202708c0c52'],
    ['À plusieurs', '032a6ed8-1d5f-4ad6-b807-c3b0273a7215'],
    ['Voix masculines', '84417a52-fc6b-4754-a574-5a4839996af2'],
    ['Voix féminines', '84417a52-fc6b-4754-a574-5a4839996af1'],
    ['Deux voix', '84417a52-fc6b-4754-a574-5a4839996af3']
]

const introSrc = 'intro/Blindher_bienvenue_sur_blindher.png'
const introMp3 = 'intro/BLINDHER APP INTRO.mp3'

export default defineComponent({
  name: 'App',
  components: {
    MobileAppStoresSuggest,
    SubscriptionForm,
    SerieCard,
    LineAudioPlayer,
    BhS3Image,
    StoryCard,
    Layout,
    Swiper, SwiperSlide,
    NSkeleton,
    NModal
  },
  data() {
    return {
      introImage: introSrc,
      introMp3: introMp3,
      introAudio: null,
      stories: [],
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      topTwoItems: [],
      sortItems: [],
      histoiresLesPlusEcoutee: [],
      LaissezVousTenter: [],
      ElleEtElle: [],
      storyIndex: [],
      loremPhoto: "images/la maison de dunes.png",
      series: [],
      histories: [],
      newStories: null,
      isSubscriber: false,
      showSubscription: false,
      trialStories: [],
      freeStoriesTag: null
    }
  },
  computed: {
    ...mapState(['showAppSuggest', 'isSubscribed', 'currentUser', 'checkIsSubscribed'])
  },
  async created() {
    if(!this.checkIsSubscribed){
      this.$store.dispatch('checkUserSubscription')
    }

    console.log('home subscription')
    console.log(this.isSubscribed)

  },
  async mounted() {

    Auth.currentAuthenticatedUser({bypassCache: true}).then(async (user) => {
      if (user) {
        this.$store.dispatch('checkUserSubscription')
        await this.getStoryList()
        await this.getSeries()
        await this.getFreeTrial()
      }
    })
  },
  watch: {

  },
  methods: {
    async stopStoreSuggest(){
      await this.$store.dispatch('showAppSuggestOff')
    },
    makeSubscription(){
      this.showSubscription = true
    },
    async getFreeTrial(){
      try {
        await DataStore.query(Tag, "634b67e4-cbf7-43b5-ac66-96f5f10e7f76" ).then(async tag => {
          if (tag) {
            this.freeStoriesTag = tag

            const stories = []
            for await (const tagStory of tag.stories) {
              const story = await tagStory.story

              stories.push(story)
              console.log(story)
            }

            this.trialStories = stories
          }
        })

      } catch (e){
        console.log(e)
      }
    },
    async getSeries(){
        try {
          await DataStore.query(Serie, Predicates.ALL, {sort: s => s.createdAt(SortDirection.DESCENDING)}).then(series =>
          {
            if (series) {
              this.series = series
            }
          })
        } catch (e) {
          console.log(e)
        }
    },
    async getStoryList() {
        try {
            const audio = await Storage.get(this.introMp3)
            if (audio) {
              this.introAudio = {
                url: audio
              }
            }
        } catch (e) {
          console.log(e)
          throw new Error('Something went wrong!')
        }

        try{
            await API.graphql(graphqlOperation(QUERY_LIST_TAG_STORIES, {storyLimit: 6})).then(response => {
              let tags = response.data.listTags.items;
              allList.forEach(tagSort => {
                let items = []
                tags.forEach(tagData => {
                  if (tagSort[1] == tagData.id) {
                    items = tagData.stories.items
                  }
                })
                const listGroup = {'name': tagSort[0], 'items': items}
                this.sortItems.push(listGroup)
              })
            })
        } catch (e) {
          console.log(e)
          throw new Error('Something went wrong!')
        }

        try{
            await DataStore.query(Story, Predicates.ALL, {
              sort: s => s.createdAt(SortDirection.DESCENDING),
              limit: 6
            }).then(storiesData => {
              if(storiesData.length > 0){
                this.newStories = storiesData
              }
            }
            )

        } catch (e) {
          console.log(e)
          throw new Error('Something went wrong!')
        }
    }
  }
})
</script>
<style lang="scss">
@import "/src/assets/scss/variables";
.home-story-list {

  img {
    object-fit: cover;
    display: inline-block;
    vertical-align: middle;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

}
.intro-box {
  background-size: cover;
  background-repeat: no-repeat;
}
.intro-image {
  position: relative;
  max-width: 100%;
}

.line-player-box {
  position: absolute;
  left: 25%;
  top: 50%;
  width: 10rem;
  margin-top: -28px;
  margin-left: -6rem;
}
.swiper-item {
  width: 250px;
}
.skeleton-wrap {
  max-width: 100%;
  overflow: hidden;
}

.n-card.n-modal,
.n-card.n-modal .subscription-box,
.subscription-box {
  background-color: $primary_background;
}

@media all and (max-width: 1024px) {

  .swiper-item {
    width: 180px;
    margin-right: 20px !important;
  }
}

@media all and (max-width: 767px) {
  .swiper-item {
    width: 160px;
    margin-right: 15px !important;
  }
}
</style>
<style scoped lang="scss">
@import "/src/assets/scss/variables";
.list-title {
  color: #fff;
}
amplify-s3-image {
  object-fit: cover;
  --height: auto;
  --width: 100%;

  vertical-align: middle;

  img {
    vertical-align: middle;
  }
}

.item-link {
  display: block;
}

.serie-list {
  text-align: left;
  a {

  }
}
</style>

