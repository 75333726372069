import {FRAGMENT_STORY_DETAIL} from "@/query/story";

export const QUERY_LiST_SERIES = /* GraphQL */ `
    query ListSeries(
        $filter: ModelSerieFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listSeries(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                createdAt
                description
                id
                image
                name
                updatedAt
                stories {
                    items {
                        id
                    }
                }
            }
            nextToken
        }
    }
`;

export const QUERY_GET_SERIE = /* GraphQL */ `
    query GetSerie($id: ID!) {
        getSerie(id: $id) {
            createdAt
            description
            id
            image
            name
            stories {
                items {
                    ...storyDetail
                }
            }
            
            updatedAt
        }
    }
    ${FRAGMENT_STORY_DETAIL}
`;