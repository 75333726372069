const myAwsConfig = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identify_pool_id": "eu-west-1:36354e5f-3c6e-4341-a25d-c6ee9e036a5e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_oulEITdCa",
    "aws_user_pools_web_client_id": "1kqrlj6j0cp39aaq596jl9h647",
    "oauth": {
        "domain": "ce9294485gxc-staging.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.blindher.com/login/",
        "redirectSignOut": "https://app.blindher.com/logout/",
        "responseType": "code"
    },
    /*"aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],*/
    "aws_cognito_social_providers": [],
    //'aws_appsync_graphqlEndpoint': 'https://tsfyvthrgbbhbpavlh4hqnyru4.appsync-api.eu-west-1.amazonaws.com/graphql',


    "aws_user_files_s3_bucket": "blindher-storage-ea4e73c984550-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://tf4tv2zsdnb2xpi7umgf7s35hm.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-cvorrsqwhjbvtbnm5n4om22cgi",

    "Storage": {
        region: "eu-west-1",
        AWSS3: {
            bucket: 'blindher-storage-ea4e73c984550-prod', //REQUIRED -  Amazon S3 bucket name
            region: 'eu-west-1', //OPTIONAL -  Amazon service region
        }
    }
    // ...
}

export default myAwsConfig