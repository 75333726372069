const AuthFormFields = {
    signIn: {
        username: {
            label: 'Email *',
            labelHidden: false,
            placeholder: 'Entrez votre email',
            order: 1
        },
        password: {
            labelHidden: false,
            label: 'Mot de passe * ',
            placeholder: 'Entrez votre mot de passe',
            isRequired: false,
            order: 3,
        },
    },
    signUp: {
        email: {
            labelHidden: false,
            label: 'Email:',
            placeholder: 'Email Address:',
            isRequired: true,
            order: 1,
        },
        password: {
            labelHidden: false,
            label: 'Password:',
            placeholder: 'Enter your Password:',
            isRequired: false,
            order: 3,
        },
        confirm_password: {
            labelHidden: false,
            label: 'Confirm Password:',
            order: 4,
        }
    },
    forceNewPassword: {
        password: {
            labelHidden: false,
            placeholder: 'Enter your Password:',
        },
    },
    resetPassword: {
        username: {
            label: 'Email',
            labelHidden: false,
            placeholder: 'Entrez votre email',
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            labelHidden: false,
            placeholder: 'Enter your Confirmation Code:',
            label: 'New Label',
            isRequired: false,
        },
        confirm_password: {
            labelHidden: false,
            placeholder: 'Enter your Password Please:',
        },
    },
    confirmSignIn: {
        confirmation_code: {
            labelHidden: false,
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
};

export function getAuthFormFields(){
    return AuthFormFields
}

export function getSeriesTotalPlayTime(stories){
    const sum = stories.reduce((accumulator, story) => {
        return accumulator + story.duration;
    }, 0);
    return sum
}