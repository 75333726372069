import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../pages/App.vue'

import {Auth, Hub, DataStore} from 'aws-amplify';
import store from '../store/store'
import StripeService from '../services/stripe-service'

const stripe = require("stripe")(process.env.VUE_APP_STRIPE_CLIENT_SECRET)

getUser().then((user) => {
  if (!user) {
    //router.push({path: '/login'});
  }
});

async function addTrial(days, afterTrailProduct){
  setTimeout(async function () {

    await Auth.currentAuthenticatedUser({bypassCache: true}).then(async currentUser => {
      if (currentUser) {

        await DataStore.query(StripeContact).then(async stripeContact => {

          if (stripeContact && stripeContact.length > 0) {

            store.commit('setStripeTempCid', stripeContact[0].customerID)
            await stripeTrial(days, stripeContact[0].customerID, afterTrailProduct)
          } else {

            const newStripeContact = await stripe.customers.create()
            if (newStripeContact) {

              await DataStore.save(new StripeContact({
                customerID: newStripeContact.id,
                stripeMode: process.env.VUE_APP_STRIPE_MODE
              }))
              store.commit('setStripeTempCid', newStripeContact.id)
              await stripeTrial(days, newStripeContact.id, afterTrailProduct)
            }
          }
        })
      }
    })
  }, 1000);
}

async function stripeTrial(days, stripeContactId, afterTrailProduct){

  if(stripeContactId){
    const session = await stripe.checkout.sessions.create({
      mode: "subscription",
      customer: stripeContactId,
      currency: 'EUR',
      line_items: [
        {
          quantity: 1,
          price: afterTrailProduct
        }
      ],
      subscription_data: {
        trial_settings: {
          end_behavior: {
            missing_payment_method: 'cancel',
          },
        },
        trial_period_days: days,
      },
      payment_method_collection: 'if_required',
      success_url: `${process.env.VUE_APP_SITE_URL}/abonnement/abonnement-reussi?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${process.env.VUE_APP_SITE_URL}/abonnement/diminue`,
    })

    if(session){
      window.location = session.url
    }
  }
}

async function addToSendingBlueList(email){
    if (email !== '') {
      console.log(email)
      let sendingBody = '{"email":"' + email + '","listIds":[41]}'
      let apiKey = process.env.VUE_APP_SENDINGBLUE_API

      const options = {
        method: 'POST',
        headers: {
          cookie: '__cf_bm=h_Df_ShmC8thZexmvfG1NqEWiEkNBCdO9L2s2V.E_ik-1683009303-0-AaMiHs98tEo3yfdmYR%2FRzdu1NxE%2Fz92SzE7i%2BRuqaW%2B2nqU2wJtlxdNoxBiia2c5FCbdYPHlPMiPOsuR7Dhtr9s%3D',
          'content-type': 'application/json',
          'api-key': apiKey
        },
        body: sendingBody
      };

      fetch('https://api.sendinblue.com/v3/contacts', options)
          .then(response => response.json())
          .then(async response => {
            if(response){
              const responseData = response
              if (responseData['code'] == 'duplicate_parameter') {
                let options = {
                  method: 'PUT',
                  headers: {
                    'content-type': 'application/json',
                    'api-key': apiKey
                  },
                  body: '{"listIds":[41],"unlinkListIds":[47]}'
                }

                await fetch('https://api.sendinblue.com/v3/contacts/' + email, options)
              }
              //router.push({path: '/compte'});
            }
          }).catch(err => console.error(err));
    }else{
      //router.push({path: '/'});
    }
}

async function getUser() {
  store.commit('setCurrentUser', null);

  return Auth.currentAuthenticatedUser().then((user) => {
    if (user) {
      store.commit('setCurrentUser', user);
      return user;
    }
  }).catch(() => {
    store.commit('setCurrentUser', null);
    return null;
  });
}

Hub.listen("auth", async (data) => {
  console.log('auth event: ' + data.payload.event)
  if (data.payload.event === 'signOut'){

    store.commit('setCurrentUser', null);
    store.commit('setCheckIsSubscribed', false)
    await DataStore.clear();
    await router.push({path: '/login'});
  } else if (data.payload.event === 'signIn') {
    //await DataStore.clear();
    //await DataStore.start();
    store.commit('setCheckIsSubscribed', false)
    await getUser();
    await StripeService.checkSubs()
    await router.push({path: '/'});

  }

  if(data.payload.event === 'signUp'){
    //await DataStore.clear();
    //await DataStore.start();
    //await getUser();

    await addToSendingBlueList(data.payload.data.user?.username)
    store.commit('setCheckIsSubscribed', false)
    //router.push({path: '/compte'});

  }

  if(data.payload.event === 'confirmSignUp'){
    /*if(store.getters.signUpTrialDays > 0){
      await addTrial(store.getters.signUpTrialDays)
    }*/
  }

    if(data.payload.event === 'autoSignIn'){

      store.commit('setCheckIsSubscribed', false)
      if(store.getters.signUpTrialDays > 0){
        await addTrial(store.getters.signUpTrialDays, store.getters.afterTrialSubscription)
      }
    }
});

const ViewStory = () => import('../pages/stories/view/App.vue')
const ListenStory = () => import('../pages/stories/Listen.vue')
const SearchPage  = () => import('../pages/search/App.vue')
import Stories from '../pages/stories/App'

import SignUp from '../pages/Inscrire'
import ForgetPassword from '../pages/MotDePasseOublie'
import SeriesPage from '../pages/serie/Series'
import {StripeContact} from "@/models";

const FavoritePage = () => import('../pages/favoris/FavoritesPage')

const MyProfile = () => import('../pages/profile/App.vue')
const ChangePassword = () => import('../pages/profile/ChangePassword.vue')
const EditInfo = () => import('../pages/profile/EditInfo.vue')
const EditEmail = () => import('../pages/profile/EditEmail.vue')
const VerifyEmail = () => import('../pages/profile/VerifyEmail.vue')
const MySubscription = () => import('../pages/profile/MySubscription.vue')
const PageNotFound = () => import('../pages/PageNotFound.vue')
const History = () => import('../pages/history/App')
const ViewSerie = () => import('../pages/serie/_id')

const TagDetail = () => import('../pages/tag/_id')

const Checkout = () => import('../pages/check-out.vue')

const LogIn = () => import('../pages/LogIn.vue')

const SubscriptionThankYou = () => import('../pages/subscription/ThankYou.vue')
const SubscriptionCancel = () => import('../pages/subscription/can-cel.vue')

const SubscriptionPage = () => import('../pages/subscription/App.vue')

const TrialRegisterThreeDays = () => import('../pages/TrialRegisterNinetyDays.vue')

const decouverteblindher = () => import('../pages/decouverteblindher.vue')
const troismoisofferts = () => import('../pages/troismoisofferts.vue')
const blissimpack = () => import('../pages/blissimpack.vue')

const adminTags = () => import('../pages/back-office/tags/App.vue')

const WellKnown = () => import('../components/WellKnown.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchPage
  },
  {
    path: '/stories/',
    name: 'Stories',
    component: Stories
  },
  {
    path: `/stories/:id`,
    name: 'story detail',
    component: ViewStory
  },
  {
    path: `/stories/:id/listen`,
    name: 'Listen Story',
    component: ListenStory
  },
  {
    path: `/favoris/`,
    name: 'Favoris',
    component: FavoritePage
  },
  {
    path: `/compte`,
    name: 'Compte',
    component: MyProfile
  },
  {
    path: `/login`,
    name: 'Compte Connecte',
    component: LogIn
  },
  {
    path: `/inscrire`,
    name: 'Inscrire',
    component: SignUp
  },
  {
    path: `/mot-de-passe-oublie`,
    name: 'Mot-de-passe-oublie',
    component: ForgetPassword
  },
  {
    path: '/compte/change-password',
    name: 'Change Password',
    component: ChangePassword
  },
  {
    path: '/compte/edit-info',
    name: 'Update Info',
    component: EditInfo
  },
  {
    path: '/compte/modifier-votre-email',
    name: 'Modifier votre email',
    component: EditEmail
  },
  {
    path: '/compte/verifier-email',
    name: 'Vérifier email',
    component: VerifyEmail
  },
  {
    path: '/compte/mes-abonnements',
    name: "Mes Abonnements",
    component: MySubscription
  },
  {
    path: '/histoires/',
    name: 'Stories',
    component: History
  },
  {
    path: `/series`,
    name: 'Séries',
    component: SeriesPage
  },
  {
    path: `/series/:id`,
    name: 'Séries View',
    component: ViewSerie
  },
  {
    path: `/tag/:id`,
    name: 'Tag',
    component: TagDetail
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not found',
    component: PageNotFound
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/abonnement',
    name: "Subscription",
    component: SubscriptionPage
  },
  {
    path: '/abonnement/abonnement-reussi',
    name: "ThankYou",
    component: SubscriptionThankYou
  },
  {
    path: '/abonnement/diminue',
    name: "Cancel",
    component: SubscriptionCancel
  },{
    path: '/cadeaublissim',
    name: 'Cadeaublissim',
    component: TrialRegisterThreeDays
  },{
    path: '/decouverteblindher',
    name: 'decouverteblindher',
    component: decouverteblindher
  },{
    path: '/troismoisofferts',
    name: 'troismoisofferts',
    component: troismoisofferts
  },{
    path: '/blissimpack',
    name: 'blissimpack',
    component: blissimpack
  },
  {
    path: '/back-office',
    children: [
      {
        path: 'tags',
        name: 'tags',
        component: adminTags
      }
    ]
  },
  {
    path: '/.well-known/apple-app-site-association',
    component: WellKnown,
    props: {
      file: 'https://app.blindher.com/apple-app-site-association/apple-app-site-association'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
