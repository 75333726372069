<template>
  <layout name="LayoutDefault">

    <authenticator :form-fields="formFields" class="auth-wrapper sign-up" initial-state="signUp">
      <template v-slot:header>
        <AuthLogo class="auth-box-space"/>
      </template>
      <template v-slot:sign-up-header>
        <h3
            class="amplify-heading"
            style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl); font-width: bold;"
        >
          Inscriez-vous
        </h3>
      </template>

      <template v-slot:sign-up-footer>
        <div style="text-align: center">
          <div class="text-center">
            <router-link to="/">Retour à la connexion</router-link>
          </div>
        </div>
      </template>
      <div className="container content-center">
        <h3 class="">Votre compte a été créé avec succès</h3>
        <div class="bh-flex my-8">
          <router-link to="/" class="bh-flex flex-column p-2 btn">Accueil</router-link>
        </div>
      </div>
    </authenticator>
  </layout>
</template>
<script>
import {  Authenticator, translations } from '@aws-amplify/ui-vue';
import AuthLogo from "@/components/AuthLogo";
import {defineComponent} from "vue";

import { I18n } from 'aws-amplify';

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

I18n.putVocabularies({
  fr: {
    'We Emailed You': 'Code envoyé !\n',
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",
    'Create Account': "S'inscrire",
    'Create account': "S'inscrire",
    'User does not exist.': "L'utilisateur n'existe pas",
    'Your passwords must match': "Vos mots de passe doivent correspondre",
    'Password must have at least 8 characters': "Le mot de passe doit comporter au moins 8 caractères",
    'Password must have numbers': "Le mot de passe doit contenir des chiffres",
    'Password must have upper case letters': "Le mot de passe doit contenir des lettres majuscules",
    'Password did not conform with policy: Password must have numeric characters': 'Votre mot de passe doit contenir des lettres et des chiffres',
    'Password must have numeric characters': 'Votre mot de passe doit contenir des lettres et des chiffres',
    'Password did not conform with policy: Password must have uppercase characters': 'Votre mot de passe doit contenir des majuscules',
    'Password must have uppercase characters': 'Votre mot de passe doit contenir des majuscules',
    'Cannot reset password for the user as there is no registered/verified email or phone_number': "Impossible de réinitialiser le mot de passe de l'utilisateur car il n'y a pas d'e-mail ou de numéro de téléphone enregistré/vérifié"
  },
});

import {darkTheme} from "naive-ui";

export default defineComponent({
  components: {Authenticator, AuthLogo},
  name: "SignUp",
  setup() {
    return {
      darkTheme
    }
  },
})
</script>
<script setup>
const formFields = {

  signUp: {
    email: {
      labelHidden: false,
      label: 'Email *',
      placeholder: 'Entrez votre email',
      isRequired: true,
      order: 1,
    },
    password: {
      labelHidden: false,
      label: 'Mot de passe *',
      placeholder: 'Entrez votre mot de passe',
      isRequired: true,
      order: 3,
    },
    confirm_password: {
      labelHidden: false,
      isRequired: true,
      label: 'Confirmez le mot de passe *',
      placeholder: 'Confirmez le mot de passe',
      order: 4,
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Enter your Password Please:',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};
</script>
<style lang="scss">
.auth-wrapper {
  .amplify-authenticator__tabs-wrapper {
    display: none;
  }
}

.auth-wrapper.sign-up {
  button.amplify-button--primary {
    margin: 2rem 0;
  }
}

.auth-box-space {
  padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
}

</style>