import { createStore } from 'vuex'
import {Auth, DataStore} from "aws-amplify";
import {StripeContact, Subs} from "@/models";

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    showAppSuggest: true,
    isSubscribed: false,
    checkIsSubscribed: false,
    currentUser: null,
    signUpTrial: 0,
    afterTrialSubscription: null,
    stripeTempCid: null,
    stripeContactID: null
}

// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    setSignUpTrail(state, days){
        state.signUpTrial = days
    },
    showAppSuggestOn (state) {
        state.showAppSuggest = true
    },
    showAppSuggestOff (state) {
        state.showAppSuggest = false
    },
    setIsSubscribed(state){
        state.isSubscribed = true
    },
    setIsNotSubscribed(state){
        state.isSubscribed = false
    },
    setCurrentUser(state, user){
        state.currentUser = user
    },
    fetchCurrentUser(state, user){
        if(!user){
            Auth.currentAuthenticatedUser({bypassCache: true}).then((user) => {
                if (user) {
                    state.currentUser = user
                }else{
                    state.currentUser = null
                }
            })
        }
    },
    setCheckIsSubscribed(state, checked){
        state.checkIsSubscribed = checked
    },
    async checkUserSubscription(state) {

        console.log('check sub test')

        await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(async currentUser => {
            if(currentUser){
                const stripeMode = process.env.VUE_APP_STRIPE_MODE
                await DataStore.query(StripeContact, sc => sc.stripeMode.eq(stripeMode)).then(async stripeContacts => {
                    if (stripeContacts && stripeContacts.length > 0) {
                        const stripe = require("stripe")(process.env.VUE_APP_STRIPE_CLIENT_SECRET)
                        const customer = await stripe.customers.retrieve(
                            stripeContacts[0].customerID,
                            {expand: ['subscriptions','subscriptions.data']});
                        if (customer) {
                            //console.log(customer)
                            if(customer.subscriptions) {
                                const lastSubscription = customer.subscriptions.data[0]
                                //console.log(lastSubscription)
                                const toDeleteSubs = await DataStore.query(Subs)
                                if (lastSubscription) {

                                    let requiredCreate = true
                                    if (toDeleteSubs) {
                                        for (const toDelete of toDeleteSubs) {
                                            if (toDelete.transactionID !== lastSubscription.id) {
                                                if (toDelete.platform === 'website') {
                                                    await DataStore.delete(toDelete)
                                                }
                                            }
                                            if (toDelete.transactionID === lastSubscription.id) {
                                                requiredCreate = false
                                            }
                                        }
                                    }

                                    if (requiredCreate) {
                                        await DataStore.save(new Subs({
                                            platform: "website",
                                            productID: lastSubscription.plan.product,
                                            transactionID: lastSubscription.id,
                                            //transactionReceipt: "invoicePDF:" + invoice.invoice_pdf
                                        }))
                                    }
                                }else{
                                    if (toDeleteSubs) {
                                        for (const toDelete of toDeleteSubs) {
                                            if (toDelete.platform === 'website') {
                                                await DataStore.delete(toDelete)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }).finally(
                    await DataStore.query(Subs).then(async mySubs => {
                        console.log('check my subs')
                        console.log(mySubs.length)
                        if (mySubs && mySubs.length > 0) {
                            state.isSubscribed = true
                            state.checkIsSubscribed = true
                        }else{
                            state.isSubscribed = false
                            state.checkIsSubscribed = true
                        }
                    })
                )

            }
        })
    },
    setStripeTempCid(state, id){
        state.stripeTempCid = id
    },
    setStripeContactID(state, id){
        state.stripeContactID = id
    },
    setAfterTrialSubscription(state, id) {
        state.afterTrialSubscription = id
    }
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    setSignUpTrail:({commit}) => commit('setSignUpTrail'),
    showAppSuggestOn: ({ commit }) => commit('showAppSuggestOn'),
    showAppSuggestOff: ({ commit }) => commit('showAppSuggestOff'),
    setIsSubscribed: ({commit}) => commit('setIsSubscribed'),
    setIsNotSubscribed: ({commit}) => commit('setIsNotSubscribed'),
    setCheckIsSubscribed: ({commit}) => commit('setCheckIsSubscribed'),
    setCurrentUser: ({commit}) => commit('setCurrentUser'),
    fetchCurrentUser: ({commit}) => commit('fetchCurrentUser'),
    checkUserSubscription: ({commit}) => commit('checkUserSubscription'),
    setStripeTempCid: ({commit}) => commit('setStripeTempCid'),
    setStripeContactID: ({commit}) => commit('setStripeContactID'),
    setAfterTrialSubscription: ({commit}) => commit('setAfterTrialSubscription')
}

const getters = {
    signUpTrialDays: function (state){
        return state.signUpTrial
    },
    afterTrialSubscription: function (state){
        return state.afterTrialSubscription
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default createStore({
    state,
    actions,
    mutations,
    getters
})