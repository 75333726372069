<script setup>
const formFields = {
  signIn: {
    username: {
      label: 'Email *',
      labelHidden: false,
      placeholder: 'Entrez votre email',
      order: 1
    },
    password: {
      labelHidden: false,
      label: 'Mot de passe * ',
      placeholder: 'Entrez votre mot de passe',
      isRequired: false,
      order: 3,
    },
  },
  signUp: {
    email: {
      labelHidden: false,
      label: 'Email:',
      placeholder: 'Email Address:',
      isRequired: true,
      order: 1,
    },
    password: {
      labelHidden: false,
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 3,
    },
    confirm_password: {
      labelHidden: false,
      label: 'Confirm Password:',
      order: 4,
    }
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      label: 'Email',
      labelHidden: false,
      placeholder: 'Entrez votre email',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Enter your Password Please:',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

</script>
<template>
  <div class="LayoutDefault " >
    <n-config-provider :theme="darkTheme" :theme-overrides="themeOverride">

        <authenticator :form-fields="formFields" class="auth-wrapper" :hide-sign-up="true">
          <template v-slot:header>
            <auth-logo class="text-center"/>
          </template>

          <template v-slot:sign-in-header>
            <h3
                class="amplify-heading text-love"
            >
              Connectez-vous
            </h3>
          </template>

          <template v-slot:sign-in-footer>
            <div style="text-align: center">
              <div class="bh-flex login-footer">
                <router-link class="text-black" to="/mot-de-passe-oublie">
                  Mot de passe oublié
                </router-link>
                <router-link class="text-black" to="/inscrire">
                  S'inscrire
                </router-link>
              </div>
            </div>
          </template>

          <template v-slot:sign-up-header>
            <h3
                class="amplify-heading"
                style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
            >

            </h3>
          </template>

          <template v-slot:sign-up-footer>
            <div style="text-align: center">
              <button
                  quaternary
                  @click="toSignIn"
              >
                Back to Sign In
              </button>

            </div>
          </template>

          <template v-slot:footer>
            <div class="text-center mt-10">
              <!--          <router-link to="/inscrire">{{ $t('register-with-us') }}</router-link>-->
            </div>
          </template>

          <template v-slot:confirm-sign-up-header>
            <h3
                class="amplify-heading"
                style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
            >
              Enter Information:
            </h3>
          </template>

          <template v-slot:confirm-sign-up-footer>
            <div></div>
          </template>


          <template v-slot:confirm-sign-in-header>
            <h3
                class="amplify-heading"
                style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
            >
              Enter Information:
            </h3>
          </template>

          <template v-slot:confirm-sign-in-footer>
            <div></div>
          </template>

          <template v-slot:reset-password-header>
            <h3
                class="amplify-heading"
            >
              Reinitialisez votre mot<br/> de passe
            </h3>
          </template>

          <template v-slot:reset-password-footer>
            <div></div>
          </template>

          <template v-slot:confirm-reset-password-header>
            <h3
                class="amplify-heading"
                style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
            >
              Enter Information:
            </h3>
          </template>

          <template v-slot:confirm-reset-password-footer>
            <div>Footer Information</div>
          </template>
          <div class="amplify-wrapper ">
            <header class="main-header ">
              <div class="container bh-flex content-space-between">
                <div class="bh-flex logo py-3">
                  <router-link to="/">
                    <n-image preview-disabled width="120" :src="require('../assets/images/blindher-logo.png')"/>
                  </router-link>
                </div>
                <nav class="bh-flex main-menu px-0">
                  <router-link v-for="menu in menus" :key="menu.url" :to="menu.url" class="bh-flex flex-column p-2">
                  <span class="icon bh-flex">
                    <Headphones32Regular v-if="menu.icon === 'headphones'"/>
                    <Search v-if="menu.icon === 'search'"/>
                    <Gift24Regular v-if="menu.icon === 'gift'"/>
                    <Heart32Regular v-if="menu.icon === 'star'"/>
                    <UserAvatar v-if="menu.icon === 'user'"/>
                  </span><span class="mt-1">{{ $t(menu.title) }}</span>
                  </router-link>
                </nav>
              </div>
            </header>
            <main class="LayoutDefault__main" >
              <slot/>
            </main>
            <footer class="LayoutDefault__footer">
            </footer>
          </div>
        </authenticator>

    </n-config-provider>
  </div>
</template>

<script>
import AuthLogo from "@/components/AuthLogo";
import { mapState } from 'vuex'
import { Authenticator,translations } from '@aws-amplify/ui-vue';
import { NImage, NConfigProvider } from "naive-ui";
import {Auth, DataStore, I18n} from 'aws-amplify';
import store from "@/store/store";

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

I18n.putVocabularies({
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",
    'Signing in': 'Connexion en cours'
  },
});

import Star28Regular from '@vicons/fluent/Star28Regular'
import UserAvatar from '@vicons/carbon/UserAvatar'
import Search from '@vicons/carbon/Search'
import Headphones32Regular from '@vicons/fluent/Headphones32Regular'
import Gift24Regular from '@vicons/fluent/Gift24Regular'
import Heart32Regular from '@vicons/fluent/Heart32Regular'
import {StripeContact, Subs} from "@/models";

export default {
  name: `LayoutDefault`,
  components: {Authenticator, NImage, UserAvatar, Headphones32Regular,Search, Gift24Regular, Heart32Regular, AuthLogo, NConfigProvider},
  data() {
    return {
      user: null,
      icons: {
        UserAvatar,
        Star28Regular
      },
      menus: [
        {
          title: "menu.Accueil",
          icon: 'headphones',
          url: '/'
        },
        {
          title: 'menu.Search',
          icon: 'search',
          url: '/search'
        },
        {
          title: 'menu.Favorite',
          icon: 'star',
          url: '/favoris'
        },
        {
          title: "menu.Profile",
          icon: 'user',
          url: '/compte'
        }
      ],
      themeOverride: {
        common: {
          primaryColor: '#D16256',
          buttonColor2: '#D16256',
        },
        Button: {
          textColor: '#FF0000'

        },
        Select: {
          peers: {
            InternalSelection: {
              textColor: '#FF0000'
            }
          }
        }
      },
      darkTheme: null
    }
  },
  computed: {
    ...mapState(['showAppSuggest', 'checkIsSubscribed', 'isSubscribed','stripeTempCid'])
  },
  async mounted() {
    setTimeout(() => {
      this.$isLoading(false)
    }, 1500);


    if(!this.checkIsSubscribed){
      await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(async currentUser => {
        if (currentUser) {
          const stripeMode = process.env.VUE_APP_STRIPE_MODE
          await DataStore.query(StripeContact, sc => sc.stripeMode.eq(stripeMode)).then(async stripeContacts => {

            if (stripeContacts && stripeContacts.length > 0) {
              this.$store.commit('setStripeContactID', stripeContacts[0].customerID)
              await this.checkStripeData(stripeContacts[0].customerID)
            }
          })
        }
      })
    }
  },
  async created() {
    this.$isLoading(true)
    // Simulate fetching user data.

    if(this.checkIsSubscribed === false){
      this.$store.dispatch('checkUserSubscription')
    }
  },
  methods: {
    async checkStripeData(contactId) {
      const stripe = require("stripe")(process.env.VUE_APP_STRIPE_CLIENT_SECRET)
      const customer = await stripe.customers.retrieve(contactId, {expand: ['subscriptions','subscriptions.data']});
      if (customer) {
        if(customer.subscriptions){
          const lastSubscription = customer.subscriptions.data[0]

          if(lastSubscription) {
            const toDeleteSubs = await DataStore.query(Subs)
            let requiredCreate = true
            if (toDeleteSubs) {
              for (const toDelete of toDeleteSubs) {
                if (toDelete.transactionID !== lastSubscription.id) {
                  if (toDelete.platform === 'website') {
                    await DataStore.delete(toDelete)
                  }
                }
                if (toDelete.transactionID === lastSubscription.id) {
                  requiredCreate = false
                }
              }
            }

            if (requiredCreate) {
              await DataStore.save(new Subs({
                platform: "website",
                productID: lastSubscription.plan.product,
                transactionID: lastSubscription.id,
                //transactionReceipt: "invoicePDF:" + invoice.invoice_pdf
              }))
            }
          }

        }else{
          /*if(dbSubscriptions){
            await DataStore.delete()
          }*/

          const toDeleteSubs = await DataStore.query(Subs)
          if(toDeleteSubs){
            for (const toDelete of toDeleteSubs) {
              if(toDelete.platform === 'website') {
                await DataStore.delete(toDelete)
              }
            }
          }
        }
        store.commit('setCheckIsSubscribed', false)
        this.$store.dispatch('checkUserSubscription')
        /*await DataStore.query(Subs).then(subs => {
          if(subs && subs.length > 0){
            store.commit('setIsSubscribed', true)
          }else{
            store.commit('setIsSubscribed', false)
          }

        })*/
      }
      console.log(this.isSubscribed)
      store.commit('setCheckIsSubscribed', true)
    }
  }
}
</script>
<style lang="sass">
@import '@aws-amplify/ui-vue/styles.css'
</style>
<style lang="scss" scoped>
@import "/src/assets/scss/variables";


header {
  background-color: $primary_background;
  color: $dark_text;

  padding: 0 1rem;

  nav {
    padding: 1rem;
    max-width: 410px;
    a {
      font-weight: 400;
      text-decoration: none;
      font-size: 0.9rem;

      &.router-link-exact-active {
        color: $love_color;
      }
    }
  }

}
.LayoutDefault__main {
  padding-left: 1rem;
  padding-right: 1rem;

  &.app-suggestion {
    padding-bottom: 8rem;
  }
}

@media (max-width: 768px) {
  .main-header nav {
    width: 100%;
  }
  .header {
    nav {
      width: 100%;
      a {
        width: 25%;
        font-size: 0.8rem;
      }
    }
  }
  .LayoutDefault__main {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}
</style>
<style lang="scss">

@import "src/assets/scss/amplify";
[data-amplify-authenticator] {
  min-height: 100vh;
}

.main-header {
  margin-right: auto;
  margin-left: auto;
  nav {
    width: 300px;
    justify-content: space-between;
    .icon {
      height: 1.4em;
    }
    svg {
      height: 1.4em;
      width: auto;
    }
  }
}

.auth-wrapper {
  background-color: $primary_background;

  [data-amplify-authenticator] [data-amplify-router] {
    background-color: transparent;
    border-color: transparent;
  }

  .amplify-heading {
    font-weight: bold;
    color: #fff;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0rem 0;
  }

  > div > div {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  input {
    background-color: #fff;
    border-radius: 999px;
    border-start-start-radius: 999px;
    border-end-start-radius: 999px;
    border-width: 0;

    border-end-end-radius: 999px !important;
    border-start-end-radius: 999px !important;
  }

  .amplify-label {
    color: $love-color;
  }

  button {
    border-radius: 999px;
    background-color: $love_color;

    &.amplify-button--primary {
      margin-top: 1.5rem;
      border-radius: 9999px !important;
    }
  }

  .amplify-field-group__outer-end .amplify-select__wrapper .amplify-select,
  .amplify-field-group__outer-end .amplify-field-group__control,
  .amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child),
  .amplify-field-group__outer-start--quiet .amplify-field-group__control,
  .amplify-field-group__outer-start .amplify-field-group__control:not(:first-child),
  .amplify-field-group :not(:first-child) .amplify-input {
    border-start-start-radius: 999px;
    border-end-start-radius: 999px;
  }
  .amplify-field-group__outer-end {

  }

  a {
    color: $love-color;
  }

  .login-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    color: $love-color;

    a {
      color: $love-color;
    }
  }

  input[type=password] + .amplify-field-group__outer-end {
    display: none;
  }

  .amplify-image {
    max-width: 100%;
    height: auto;
  }

  [data-amplify-router] {
    display: flex;
    flex-direction: column;

    > div {
      order: 1;

      &:first-child {
        order: 2;
      }
    }
  }

  .amplify-field-group {
    position: relative;
  }
  .amplify-button {
    &.amplify-field__show-password {
      background: transparent;
      border: none;
      position: absolute;
      right: 0;
    }
  }

  p.amplify-text--error {
    font-size: 0.8rem;
    color: red;
    &:before {
      content: "- ";
      padding-right: 5px;
      display: inline-block;
    }
  }
}

[data-amplify-authenticator] [data-amplify-container] {
  width: 340px;
  max-width: calc(100vw - 2rem);
}

.LayoutDefault {
  margin-right: auto;
  margin-left: auto;

  .logout {
    color: #fff;
  }

  .logo {
    align-items: center;
  }

  &__nav {
    padding-left: 1rem;
    padding-right: 0;
    padding-top: 1em;
    padding-bottom: 1em;

    > div > div {
      display: flex;
      justify-content: center;
    }

    a {
      text-decoration: none;
      display: flex;
      justify-content: center;
      flex-direction: column;

      padding: 0.3rem 0.5rem;

      svg {
        margin-bottom: 0.3rem;
      }

      font-size: 1.5rem;
      span {
        font-size: 0.7rem;
      }
    }
  }
  &__user {
    float: right;
  }
  &__main {
    padding-top: 1.5em;
    padding-bottom: 2em;
  }
  &__footer {
    padding-top: 1em;
    padding-bottom: 1em;

  }

  @media (max-width: 768px) {
    nav {
      a {
        span {
          font-size: 0.8rem;
        }
      }
    }
    .logo {
      margin-top: 1rem;
    }

  }
}
</style>
<style lang="scss">
@import "/src/assets/scss/variables";
footer .login-footer {
  padding: 0 3rem;
  justify-content: space-around;
}

.amplify-heading {
  padding: 1rem;
}
.auth-wrapper {
  img {
    max-width: 100%;
    height: auto;
  }
  [data-amplify-form] {
    padding: 1rem;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: $dark_text;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>