<template>
  <layout name="LayoutDefault">
    <n-config-provider :theme="darkTheme">
      <authenticator>
            <div v-if="stories">

                <div v-for="story in stories" :key="story.id" style="width: 20%">
                  <a :href="`/stories/${story.id}`">

                    <h4>{{story.name}}</h4>
                  </a>

                </div>
            </div>

      </authenticator>

    </n-config-provider>
  </layout>
</template>

<script>
import { defineComponent } from 'vue'
import { API } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-vue';
import {listStories} from "@/graphql/queries";
import Layout from './../../layouts/layout';
import { NConfigProvider, darkTheme } from 'naive-ui'

export default defineComponent({
  setup() {
    return {
      darkTheme
    }
  },
  name: 'App',
  components: {
    Authenticator,
    NConfigProvider,
    Layout,
  },
  data() {
    return {
      stories: []
    }
  },
  async created() {
    this.$isLoading(false)
    await this.getStoryList()
  },
  methods: {
    async getStoryList() {
      try {
        const stories = await API.graphql({
          query: listStories
        });
        if(stories){
          this.stories = stories.data.listStories.items
          console.log(this.stories)
        }
      } catch (e) {
        throw new Error('Something went wrong!')
      }
      this.$isLoading(false)
    }
  }
})
</script>
<style scoped lang="scss">
@use '/src/assets/scss/main.scss';

.carousel-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
