<template>
  <div class="app-down-load-suggest py-5 px-3">
    <div class="container">
      <div class="bh-flex">
        <div class="flex-grow">
          <div class="bh-flex justify-flex-start">
            <icon class="mr-3 cursor-pointer" @click="$emit('close', $event)" size="24">
              <close-circle-filled />
            </icon>
            Télécharger l'application Blindher Stories
          </div>
        </div>
        <div class="app-links">
          <a class="mr-3" href="https://apps.apple.com/app/id1631658847">
            <n-image
                preview-disabled
                height="50"
                class="amplify-image"
                alt="Apple App Store"
                :src="require('../assets/images/app_store_badge_fr.png')"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.blindher&amp;gl=FR">
            <n-image
                height="50"
                class="amplify-image"
                alt="Google Play Store"
                preview-disabled
                :src="require('../assets/images/google-play-badge.png')"
            />
          </a>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { NImage} from 'naive-ui'

import {Icon} from '@vicons/utils'
import CloseCircleFilled from '@vicons/antd/CloseCircleFilled'

export default {
  name: 'MobileAppStoresSuggest',
  components: { NImage, Icon, CloseCircleFilled},
}
</script>
<style scoped lang="scss">
.app-down-load-suggest {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #D39781;

  .container > .bh-flex {
    justify-content: space-between;
  }

  .app-links {
    img {
      height: 80px;
      width: auto;
    }
  }

  @media all and (max-width: 768px){
    .container > .bh-flex {
      flex-direction: column;
    }

    .app-links {
      margin-top: 1rem;
    }
  }
}
</style>