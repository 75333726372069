<template>
  <div>
    <main class="audioPlayer text-center mb-4 px-3" >
      <div class="audioPlayerUI">
        <div class="playerButtons ">
          <div class="play-button is-inline-block text-white" :class="isPlayingClass" v-on:click="playAudio()" title="Play/Pause Song">
            <Pause12Filled class="pause-button" v-if="playing"/>
            <Play12Filled class="play-button" v-else/>
          </div>
        </div>
        <div class="player-control mt-2" v-if="playing">
          <vue3-slider v-model="playStep" :formatTooltip="currentTimeFormat" :step="0.1" :height="3" :handleScale="5"
                       @drag-start="slideDragStart"
                       @drag-end="sliderDragEnd"
                       :alwaysShowHandle="true" color="#D16256" track-color="#485770" :tooltip="true" />
          <div class="currentTimeContainer" style="text-align:center">
            <span class="currentTime">{{ currentFancyTimeFormat }}</span>
            <span class="totalTime"> {{ trackDurationFancyTimeFormat }}</span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

import slider from "vue3-slider"

import Play12Filled from '@vicons/fluent/Play12Filled'

import Pause12Filled from '@vicons/fluent/Pause12Filled'

export default defineComponent({
  name: "LineAudioPlayer",
  components: { Play12Filled, Pause12Filled, "vue3-slider": slider },
  props: {
    audioMedia: {
      type: Object,
      default: null
    },
    duration: {
      type: Number,
      default: 0
    },
    pauseAudio: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      listenTrack: true,
      playStep: 0,
      audio: '',
      imgLoaded: false,
      currentlyPlaying: false,
      currentlyStopped: false,
      currentTime: 0,
      checkingCurrentPositionInTrack: "",
      trackDuration: 0,
      currentProgressBar: 0,
      currentSong: true,
      debug: false,
      audioFile: "",
      playRate: 1,
      isPlayingClass: '',
      currentUserId: '',
      playing: false
    }
  },
  created: function() {
    this.prepareSong();
    this.audio.loop = false;
  },
  mounted() {

  },
  computed: {
    currentFancyTimeFormat(){
      return new Date(this.currentTime * 1000).toISOString().substring(14, 19)
    },
    trackDurationFancyTimeFormat(){
      return new Date(this.trackDuration * 1000).toISOString().substring(14, 19)
    }
  },
  methods: {
    prepareSong() {
      let wasPlaying = this.currentlyPlaying;
      if(this.audioMedia.url !== ''){
        this.audioFile = this.audioMedia.url;
        this.audio = new Audio(this.audioFile);
        let localThis = this;

        this.audio.addEventListener("loadedmetadata", function() {
          localThis.trackDuration = Math.round(this.duration);
        });

        this.audio.addEventListener("ended", this.handleEnded);

        if (wasPlaying) {
          this.playAudio()
        }
        if(this.pauseAudio == false){
          this.playAudio()
        }
      }
    },
    getCurrentSong() {
      return this.audioMedia;
    },
    playAudio() {
      this.audio.playbackRate = 1
      if (!this.currentlyPlaying) {
        this.getCurrentTimeEverySecond(true);
        this.currentlyPlaying = true;
        this.audio.play();

        this.playing = true

        this.isPlayingClass = 'active'
      } else {
        this.pausedMusic()
        this.playing = false

        this.isPlayingClass = ''
      }
      this.currentlyStopped = false;
    },
    stopAudio: function() {
      this.audio.pause();
      this.currentlyPlaying = false;
      this.pausedMusic();
    },
    handleEnded: function() {
      //this.stopAudio();
      this.currentlyPlaying = false;
    },
    getCurrentTimeEverySecond: function() {
      if(this.audio){
        let localThis = this;
        this.checkingCurrentPositionInTrack = setInterval(
            ()=> {
              if(this.listenTrack == true){
                localThis.playStep = localThis.audio.currentTime / localThis.trackDuration * 100;
                localThis.currentTime = localThis.audio.currentTime;
                localThis.currentProgressBar =
                    localThis.audio.currentTime / localThis.trackDuration * 100;
              }
            },
            1000
        );
      }
    },
    slideDragStart(){
      this.listenTrack = false
    },
    sliderDragEnd(value){
      let localThis = this
      localThis.playStep = parseFloat(value)
      localThis.audio.currentTime = Math.round((localThis.trackDuration/100) * value)
      this.listenTrack = true
    },
    async pausedMusic() {
      this.playRate = 1
      this.currentlyPlaying = false;
      this.audio.pause()
      clearTimeout(this.checkingCurrentPositionInTrack);
    },
    toggleDebug: function(){
      this.debug=!this.debug;
      document.body.classList.toggle('debug');
    },
    currentTimeFormat(){
      let trackTime = Math.round((this.trackDuration/100) * this.playStep)
      if(trackTime){
        return new Date(trackTime * 1000).toISOString().substring(14, 19)
      }
      return new Date(this.currentTime * 1000).toISOString().substring(14, 19)
    },
  },
  watch: {
    /*currentTime: function() {
      this.currentTime = Math.round(this.currentTime);
    },*/
    playStep: {
      handler(value){
        if(this.listenTrack == true){
          this.currentTrack = value
        }
      }
      /*handler(value){
        if(this.audio){
          let localThis = this;
          this.audio.currentTime = (localThis.trackDuration/100) * value
        }
      }*/
    }
  }
})
</script>
<style scoped lang="scss">
@import "/src/assets/scss/variables";
$player_width: 20rem;
$player_padding: 1.5rem;
$player_border_radius: 0.5rem;

$button_size: 1.5rem;

$anim_duration: 0.5s;

$drag_size: 2rem;

.animated {
  animation-duration: $anim_duration;
}
.audioPlayer {
  position: relative;

  overflow: hidden;

  width: 35rem;

  max-width: 100%;
  margin: 0 auto;

  user-select: none;

  .player-control {
    margin: 0 auto;
  }
  .audioPlayerUI {
    margin-top: $player_padding;
    will-change: transform, filter;
    transition: $anim_duration;
    &.isDisabled {
      transform: scale(0.75) translateX(100%);
      filter: blur(5px) grayscale(100%);
    }
    .albumDetails {
      text-align: center;
      margin: 2rem 0;

      p {
        margin: 0px;
        &.artist {
          margin-top: 0.5rem;
          font-size: 0.75rem;
          font-weight: normal;
          color: $primary_color;
          transition-delay: 100ms;
        }
      }
    }
    .albumImage {
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        z-index: 10;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius:$player_border_radius;
      }
    }
    .playerButtons {
      position: relative;
      margin: 0 auto;
      text-align: center;

      .button {
        font-size: $button_size;
        display: inline-block;
        vertical-align: middle;
        padding: 0 0.5rem;
        margin: 0 0.25rem;
        color: rgba(0, 0, 0, 0.75);
        border-radius: 50%;

        color: #fff;

        outline: 0;
        text-decoration: none;
        cursor: pointer;
        transition: $anim_duration;

        &.play {
          font-size: 2*$button_size;
          margin: 0 1.5rem;

          padding: 0.5rem;
          padding-left: 0.6rem;
          padding-right: 0.4rem;
        }
        &.active {
          opacity: 0.75;
          padding-left: 0.5rem;
          padding-right: 0.5rem ;
        }
        &.isDisabled {
          color: rgba(0, 0, 0, 0.2);
          cursor: initial;
        }
        &.isDisabled:active {
          transform: none;
        }
      }
    }
    .currentTimeContainer {
      width: 100%;
      height: 1rem;

      display: flex;
      justify-content: space-between;

      .currentTime,
      .totalTime {
        font-size: 1.2rem;
        font-family: monospace;
      }
    }

    .currentProgressBar {
      width: 100%;
      background-color: #fff;
      border-radius: 3px;
      margin: $drag_size 0;
      .currentProgress {
        background-color: #fff;
        width: 0px;
        height: 6px;
        transition: 100ms;
        position: relative;
        border-radius: 3px;

        &:after {
          content: "";
          width: $drag_size;
          height: $drag_size;
          position: absolute;
          border-radius: 50%;
          right: 0;
          top: -0.8rem;
          background-color: #fff;
          display: inline-block;
        }
      }
    }
  }
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
}

/* data change transitions */

.fade-enter-active,
.fade-leave-active {
  transition: opacity $anim_duration;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}


.play {
  background-color: #fff;
  border-radius: 50%;

  svg {
    width: 30px;
    height: 30px;
    padding-top: 0;
    margin: 0px auto;
    display: block;
    fill: $secondary_color;
    stroke: $secondary_color;
    color: $secondary_color;

    circle {
      stroke: #fff;
    }
  }
}
.speed {
  width: 4rem;
  height: auto;
}
#circle {
  transition: stroke-dashoffset 300ms ease-in;
  stroke-dashoffset: 0;
  fill: none;
}
#circle.play {
  stroke-dashoffset: 314;
}

.audioPlayer .audioPlayerUI .currentProgressBar .currentProgress {
  min-width: $drag_size;
}

@media (max-width: 768px) {
  .line-player-box {
    .playerButtons {
      svg {
        width: 55px;
      }

      svg.pause-button {
        width: 40px;
      }
    }
  }

  .audioPlayer {
    .player-control {
      padding: 0 10px;
    }
  }
  .line-player-box .audioPlayer .audioPlayerUI .currentTimeContainer span.currentTime,
  .line-player-box .audioPlayer .audioPlayerUI .currentTimeContainer span.totalTime {
    font-size: 0.8rem;
  }
}

</style>
<style lang="scss">
$drag_size: 1rem;
.line-player-box {
  .playerButtons {
    .play-button {
      cursor: pointer;
    }
    button {
      border: 0;
      background: transparent;
      color: #fff;
    }
    svg {
      width: 70px;
    }
  }
  .audioPlayer .audioPlayerUI {
    margin-top: 0;
  }
  .play-slider.n-slider  {
    margin-bottom: 3px;
    .n-slider-handles .n-slider-handle-wrapper {
      .n-slider-handle {
        width: $drag_size;
        height: $drag_size;
      }
    }
    .n-slider-rail {
      background-color: #ffffff;
      .n-slider-rail__fill {
        background-color: #ffffff;
      }
    }

  }
  .audioPlayer .audioPlayerUI .currentTimeContainer {
    height: auto;

    span.currentTime,
    span.totalTime {
      font-size: 0.9rem;
    }
  }
}

</style>