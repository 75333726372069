<template>
  <div class="story-card radius-medium p-2 mobile-p-1">
    <div class="is-relative">
      <bh-s3-image class="radius-medium bh-flex image" :src="image"/>
      <span class="duration is-absolute radius-half-round py-1 px-3">{{secondToMinute(duration)}}</span>
    </div>

    <div class="info text-left">
      <h5 class="mt-2 mb-0 bh-flex content-start"><LockClosed class="mr-1 lock-story " v-if="!isTrial && !isSubscribed"/>{{name}}</h5>
    </div>
  </div>
</template>
<script>
import {secondToMinute} from '../../lib/time'
import BhS3Image from "@/components/BhS3Image";
import LockClosed from "@vicons/ionicons5/LockClosed";
export default {
  name: "StoryCard",
  methods: {secondToMinute},
  components: {BhS3Image, LockClosed},
  props: {
    id: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    isTrial: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    duration: {
      type: Number,
      default: 0
    },
    file: {
      type: String,
      default: ''
    },
    class: {
      type: String,
      default: ''
    },
    isSubscribed: {
      type: Boolean,
      default: false
    }
  },
  created() {
    console.log(this.isSubscribed)
    console.log('is trial:')
    console.log(this.isTrial)
  },
  computed: {
    storyDuration(){
      return secondToMinute(this.duration)
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
.story-card {
  background: $highlight_background;
  color: #fff;
}
.image {
  width: 100%;
  height: 100%;
}
.duration {
  background: #fff;
  color: #414141;
  bottom: 10px;
  left: 10px;
}
@media (max-width: 768px) {
  .duration {
    font-size: 0.7rem;
  }
  .lock-story {
    width: 18px;
  }
}
</style>