/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSerie = /* GraphQL */ `
  query GetSerie($id: ID!) {
    getSerie(id: $id) {
      id
      name
      image
      description
      stories {
        items {
          id
          name
          description
          file
          level
          duration
          image
          serieID
          isTrial
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          storyAuthorId
          storyReaderId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSeries = /* GraphQL */ `
  query ListSeries(
    $filter: ModelSerieFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listSeries(
      filter: $filter
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        image
        description
        stories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSeries = /* GraphQL */ `
  query SyncSeries(
    $filter: ModelSerieFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSeries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        image
        description
        stories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      name
      tags {
        items {
          id
          personID
          tagID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPeople = /* GraphQL */ `
  query ListPeople(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tags {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPeople = /* GraphQL */ `
  query SyncPeople(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPeople(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        tags {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStory = /* GraphQL */ `
  query GetStory($id: ID!) {
    getStory(id: $id) {
      id
      name
      description
      file
      level
      duration
      author {
        id
        name
        tags {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      reader {
        id
        name
        tags {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      image
      history {
        items {
          id
          storyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      likes {
        items {
          id
          storyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          storyID
          tagID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      serieID
      isTrial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      storyAuthorId
      storyReaderId
    }
  }
`;
export const listStories = /* GraphQL */ `
  query ListStories(
    $filter: ModelStoryFilterInput
    $sort: ModelStorySort
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listStories(
      filter: $filter
      sort: $sort
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        file
        level
        duration
        author {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reader {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        image
        history {
          nextToken
          startedAt
        }
        likes {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        serieID
        isTrial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        storyAuthorId
        storyReaderId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStories = /* GraphQL */ `
  query SyncStories(
    $filter: ModelStoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        file
        level
        duration
        author {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reader {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        image
        history {
          nextToken
          startedAt
        }
        likes {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        serieID
        isTrial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        storyAuthorId
        storyReaderId
      }
      nextToken
      startedAt
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      story {
        id
        name
        description
        file
        level
        duration
        author {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reader {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        image
        history {
          nextToken
          startedAt
        }
        likes {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        serieID
        isTrial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        storyAuthorId
        storyReaderId
      }
      storyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        story {
          id
          name
          description
          file
          level
          duration
          image
          serieID
          isTrial
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          storyAuthorId
          storyReaderId
        }
        storyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHistories = /* GraphQL */ `
  query SyncHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        story {
          id
          name
          description
          file
          level
          duration
          image
          serieID
          isTrial
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          storyAuthorId
          storyReaderId
        }
        storyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      persons {
        items {
          id
          personID
          tagID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stories {
        items {
          id
          storyID
          tagID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        persons {
          nextToken
          startedAt
        }
        stories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTags = /* GraphQL */ `
  query SyncTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        persons {
          nextToken
          startedAt
        }
        stories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStoryLike = /* GraphQL */ `
  query GetStoryLike($id: ID!) {
    getStoryLike(id: $id) {
      id
      story {
        id
        name
        description
        file
        level
        duration
        author {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reader {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        image
        history {
          nextToken
          startedAt
        }
        likes {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        serieID
        isTrial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        storyAuthorId
        storyReaderId
      }
      storyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listStoryLikes = /* GraphQL */ `
  query ListStoryLikes(
    $filter: ModelStoryLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoryLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        story {
          id
          name
          description
          file
          level
          duration
          image
          serieID
          isTrial
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          storyAuthorId
          storyReaderId
        }
        storyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStoryLikes = /* GraphQL */ `
  query SyncStoryLikes(
    $filter: ModelStoryLikeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStoryLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        story {
          id
          name
          description
          file
          level
          duration
          image
          serieID
          isTrial
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          storyAuthorId
          storyReaderId
        }
        storyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubs = /* GraphQL */ `
  query GetSubs($id: ID!) {
    getSubs(id: $id) {
      id
      platform
      productID
      transactionID
      transactionReceipt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listSubs = /* GraphQL */ `
  query ListSubs(
    $filter: ModelSubsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        platform
        productID
        transactionID
        transactionReceipt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubs = /* GraphQL */ `
  query SyncSubs(
    $filter: ModelSubsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        platform
        productID
        transactionID
        transactionReceipt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTagPerson = /* GraphQL */ `
  query GetTagPerson($id: ID!) {
    getTagPerson(id: $id) {
      id
      personID
      tagID
      person {
        id
        name
        tags {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        name
        persons {
          nextToken
          startedAt
        }
        stories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTagPeople = /* GraphQL */ `
  query ListTagPeople(
    $filter: ModelTagPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personID
        tagID
        person {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTagPeople = /* GraphQL */ `
  query SyncTagPeople(
    $filter: ModelTagPersonFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTagPeople(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        personID
        tagID
        person {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTagStory = /* GraphQL */ `
  query GetTagStory($id: ID!) {
    getTagStory(id: $id) {
      id
      storyID
      tagID
      story {
        id
        name
        description
        file
        level
        duration
        author {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        reader {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        image
        history {
          nextToken
          startedAt
        }
        likes {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        serieID
        isTrial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        storyAuthorId
        storyReaderId
      }
      tag {
        id
        name
        persons {
          nextToken
          startedAt
        }
        stories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTagStories = /* GraphQL */ `
  query ListTagStories(
    $filter: ModelTagStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagStories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storyID
        tagID
        story {
          id
          name
          description
          file
          level
          duration
          image
          serieID
          isTrial
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          storyAuthorId
          storyReaderId
        }
        tag {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTagStories = /* GraphQL */ `
  query SyncTagStories(
    $filter: ModelTagStoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTagStories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        storyID
        tagID
        story {
          id
          name
          description
          file
          level
          duration
          image
          serieID
          isTrial
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          storyAuthorId
          storyReaderId
        }
        tag {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
