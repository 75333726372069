// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Serie, Person, Story, History, Tag, StoryLike, Subs, StripeContact, TagPerson, TagStory } = initSchema(schema);

export {
  Serie,
  Person,
  Story,
  History,
  Tag,
  StoryLike,
  Subs,
  StripeContact,
  TagPerson,
  TagStory
};