import {Auth, DataStore} from "aws-amplify";
import {StripeContact, Subs} from "@/models";
import store from "@/store/store";

class StripeService {
    static async checkSubs(){
        //console.log('call stripe')
        await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(
            async currentUser => {

                const stripe = require("stripe")(process.env.VUE_APP_STRIPE_CLIENT_SECRET)
                const email = currentUser.attributes.email
                const customerSearch = await stripe.customers.search({
                    query: 'email:"' + email + '"'
                })

                if (customerSearch) {
                    if (customerSearch.data[0]) {
                        const contact = await stripe.customers.retrieve(customerSearch.data[0].id, {expand: ['subscriptions','subscriptions.data']});
                        //console.log(contact)

                        await DataStore.query(StripeContact).then(async stripeContact => {
                            if (stripeContact && stripeContact.length > 0) {
                                store.commit('setStripeTempCid', stripeContact[0].customerID)
                            } else {
                                await DataStore.save(new StripeContact({
                                    customerID: contact.id,
                                    stripeMode: process.env.VUE_APP_STRIPE_MODE
                                }))
                                store.commit('setStripeTempCid', contact.id)
                            }
                        })
                        //console.log(contact.subscriptions)

                        if(contact.subscriptions){
                            const lastSubscription = contact.subscriptions.data[0]

                            //console.log(lastSubscription)

                            if(lastSubscription) {
                                const toDeleteSubs = await DataStore.query(Subs)
                                let requiredCreate = true
                                if (toDeleteSubs) {
                                    for (const toDelete of toDeleteSubs) {
                                        if (toDelete.transactionID !== lastSubscription.id) {
                                            if (toDelete.platform === 'website') {
                                                await DataStore.delete(toDelete)
                                            }
                                        }
                                        if (toDelete.transactionID === lastSubscription.id) {
                                            requiredCreate = false
                                        }
                                    }
                                }

                                if (requiredCreate) {
                                    await DataStore.save(new Subs({
                                        platform: "website",
                                        productID: lastSubscription.plan.product,
                                        transactionID: lastSubscription.id,
                                        //transactionReceipt: "invoicePDF:" + invoice.invoice_pdf
                                    }))
                                }
                            }
                        }else{
                            const toDeleteSubs = await DataStore.query(Subs)
                            if(toDeleteSubs){
                                for (const toDelete of toDeleteSubs) {
                                    if(toDelete.platform === 'website') {
                                        await DataStore.delete(toDelete)
                                    }
                                }
                            }
                        }
                    }
                }
            })
    }
}

export default StripeService